.project-section {
  background-color: var(--background-color-white);
}

.project__websites {
  max-width: 120rem;
}

.project__image-container {
  max-width: 100rem;
  overflow: hidden;
}

.project__image {
  backface-visibility: hidden;
  width: 100%;
  transition: all .3s;
}

.project__image-link:hover {
  scale: 1.2;
}

.project__websites {
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  padding: 4.8rem;
  display: grid;
}

.project__subheader-title {
  text-align: center;
  text-transform: capitalize;
  font-size: 3.2rem;
}

.project__link-container {
  text-align: center;
  justify-content: center;
  align-items: center;
  max-width: 20rem;
  padding: 1.2rem;
  display: flex;
}

.project__links {
  color: var(--dark-color);
  font-size: 4rem;
  text-decoration: none;
}

.project__websites__content-container {
  justify-content: baseline;
  flex-direction: column;
  align-items: center;
  padding: 2.4rem 1.8rem;
  font-size: 1.8rem;
  display: flex;
}

.project__email-container {
  justify-content: center;
  align-items: center;
  padding: 0 2.4rem;
  display: flex;
}

.project__email {
  max-width: 100rem;
  padding: 2.4rem;
}

.project__image__email-container {
  max-width: 30rem;
  overflow: hidden;
}

.project__subheader-title-email {
  text-align: center;
  font-size: 1.8rem;
}

.project__pad {
  padding-bottom: 0;
}

.project__outer-link {
  color: var(--secondary-color);
}

/*# sourceMappingURL=index.d3ff9127.css.map */
